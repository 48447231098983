@import url("https://fonts.googleapis.com/css?family=Lato");

html,
body,
#app,
#app > div {
  height: 100%;
  font-family: "Lato", source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body > #root > div {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
